var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { title: "等待支付", footer: null, width: 300 },
          on: { ok: _vm.handleClose },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "margin-bottom": "20px",
                "text-align": "center"
              }
            },
            [
              _vm.apiRes.payDataType == "codeImgUrl"
                ? _c("img", { attrs: { src: _vm.apiRes.payData, alt: "" } })
                : _vm.apiRes.payDataType == "payurl"
                ? _c(
                    "span",
                    [
                      _vm._v("等待用户支付 "),
                      _c("hr"),
                      _vm._v(" 如浏览器未正确跳转请点击： "),
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.apiRes.payData, target: "_blank" }
                        },
                        [_vm._v("支付地址")]
                      ),
                      _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.apiRes.payData,
                              expression: "apiRes.payData",
                              arg: "copy"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.onCopy,
                              expression: "onCopy",
                              arg: "success"
                            }
                          ],
                          staticClass: "copy-btn",
                          attrs: { size: "small" }
                        },
                        [_vm._v("复制链接")]
                      )
                    ],
                    1
                  )
                : _c("span", [_vm._v("等待用户支付,请稍后")])
            ]
          ),
          _c("p", { staticClass: "describe" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.wxApp,
                  expression: "wxApp"
                }
              ],
              attrs: { src: require("@/assets/payTestImg/wx_app.svg"), alt: "" }
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.aliApp,
                  expression: "aliApp"
                }
              ],
              attrs: {
                src: require("@/assets/payTestImg/ali_app.svg"),
                alt: ""
              }
            }),
            _c("span", [_vm._v(_vm._s(_vm.payText))])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }